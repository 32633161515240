@import 'global/styles';

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --cl-color-primary-light: #97d3cc;
}

#otherRoot {
  position: relative;
  z-index: 9999992;
}

body {
  margin: 0;
  font-family: $font-family;
  background-color: $body-background;
  overflow-x: hidden;
  min-height: 100vh;
  scroll-behavior: smooth;
}

button,
input,
textarea {
  font-family: $font-family;
}

p {
  margin: 0;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #a4a4a4;
  border-radius: 8px;
  border: 1.5px solid transparent;
  background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
  border: 0px solid transparent;
}

.mouse-effect {
  border: none;
  background: transparent;
  cursor: pointer;

  &:hover {
    background: #e6eaea !important;
  }

  &:focus,
  &:active,
  &:focus-within {
    background: #dfdfdf !important;
  }
}

.mouse-effect-no-within {
  @extend .mouse-effect;

  &:focus,
  &:active,
  &:focus-within {
    background: #dfdfdf !important;
  }
}

.cell-mouse-effect-div,
.cell-mouse-effect {
  @extend .mouse-effect;
  flex: 1;
  width: 100%;
  display: block;
}

.cell-mouse-effect-black-font {
  @extend .cell-mouse-effect;

  &:hover {
    color: #484848 !important;
  }
}

.cell-mouse-effect-no-display-no-within {
  @extend .mouse-effect-no-within;
}

.cell-mouse-effect-no-display-no-within-red-btn {
  @extend .mouse-effect-no-within;

  &:hover:not([disabled]),
  &:focus:not([disabled]),
  &:active:not([disabled]),
  &:focus-within:not([disabled]) {
    background: #ec7765 !important;
  }

  &:hover:is([disabled]),
  &:focus:is([disabled]),
  &:active:is([disabled]),
  &:focus-within:is([disabled]) {
    background: #f5998b !important;
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.cell-mouse-effect-no-display-no-within-padding-lr-15 {
  @extend .mouse-effect-no-within;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.cell-mouse-effect-no-display {
  @extend .mouse-effect;
}

.cell-mouse-effect-no-display-padding-10 {
  @extend .mouse-effect;
  padding: 10px;
}

.cell-mouse-effect-block-align-left {
  @extend .mouse-effect;
  text-align: left;
  display: block;
}

.cell-mouse-effect-nav {
  padding: 10px 10px !important;
  height: 50px;
  ;
  border-radius: 10px;
  border: none;
  background: transparent;
  cursor: pointer;

  &:hover {
    background: #e6eaea !important;
  }
}

.cell-mouse-effect-nav-no-within {
  @extend .mouse-effect-no-within;
  padding: 10px 10px !important;
  height: 50px;
  ;
  border-radius: 10px;
}

.transparent-btn {
  background: transparent;
  border: none;
  display: block;
  width: 100%;
}

.cell-mouse-effect.cell-absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  left: 0;
}

.margin-right-10 {
  margin-right: 10px;
}

.flex {
  display: flex;

  &.flex-loading svg {
    width: 40px;
  }

  &.flex-wrap,
  .flex-wrap {
    flex-wrap: wrap;
  }

  &.flex-row {
    flex-direction: row;
  }

  &.flex-column {
    flex-direction: column;
  }

  &.flex-center,
  .flex-center {
    align-items: center;
    justify-content: center;
  }

  &.flex-align-start,
  .flex-align-start {
    align-items: flex-start;
    justify-content: center;
  }

  &.flex-align-center {
    align-items: center;
  }

  &.flex-align-end,
  .flex-align-end {
    align-items: flex-end;
    justify-content: center;
  }

  &.flex-between,
  .flex-between {
    align-items: center;
    justify-content: space-between;
  }

  &.flex-start,
  .flex-start {
    align-items: center;
    justify-content: flex-start;
  }

  &.flex-end,
  .flex-end {
    align-items: center;
    justify-content: flex-end;
  }

  .flex1 {
    flex: 1;
  }
}

.auto-wrap {
  word-wrap: break-word;
  word-break: normal;
}

.force-wrap {
  word-break: break-all;
}

.Calendar__day.-selectedBetween {
  background: #97D3CC !important;
}

[class*="navDate"] .Calendar__day {
  padding: unset;
}

.product-disable-scale:hover,
.product-disable-scale-12 {
  & {
    // background: $white;
    // box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
    transform: scale(1);
    transform-origin: center center;
    z-index: 11;
    transition: transform 0.1s ease-in-out;

    .content {
      .addToLookbook {
        display: flex !important;

        button {
          border-radius: 0;
          height: 18px;
          width: 18px;
          padding: 0;
          margin: 0;

          >div {
            margin-right: 0px;
            position: initial;
            display: flex;
            justify-content: flex-end;
            margin-left: 0px;
          }
        }
      }
    }
  }
}

.scale_121:hover,
.scale_12 {
  & {
    transform: scale(1.1);
    transform-origin: center center;
    z-index: 11;
    transition: transform 0.1s ease-in-out;

    .content {
      .addToLookbook {
        display: flex !important;

        button {
          border-radius: 0;
          height: 18px;
          width: 18px;
          padding: 0;
          margin: 0;

          >div {
            margin-right: 0px;
            position: initial;
            display: flex;
            justify-content: flex-end;
            margin-left: 0px;
          }
        }
      }
    }
  }
}

.scale_121_crp:hover,
.scale_12_crp {
  & {
    transform: scale(1.09);
    transform-origin: center center;
    z-index: 11;
    transition: transform 0.1s ease-in-out;

    .content {
      .addToLookbook {
        display: flex !important;

        button {
          border-radius: 0;
          height: 18px;
          width: 18px;
          padding: 0;
          margin: 0;

          >div {
            margin-right: 0px;
            position: initial;
            display: flex;
            justify-content: flex-end;
            margin-left: 0px;
          }
        }
      }
    }
  }
}

.ant-popover-inner:has(ul.addToLookbookInProduct) {
  padding: 2px 8px !important;
  color: #01a699 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  border-radius: 4px !important;
  font-family: Nunito Sans,sans-serif !important;
}
ul.addToLookbookInProduct {
  list-style: none;
  padding-bottom: 4px;

  li {
    width: 200px;
    line-height: 32px;
    box-sizing: border-box;
    border-bottom: 1px solid #eeeeee;
    padding: 0px 0px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;

    .saveToText {
      font-size: 10px;
      cursor: default;
    }

    div {
      color: #000;
      cursor: default;
    }

    &:last-child {
      border-bottom: transparent;
      display: flex;
    }

    svg {
      fill: $green;
      width: 18px;
      height: 18px;
    }

    .createNewText {
      margin: 1px 0 0 5px;
      color: $green;
    }

    &.removeLookbook {
      svg {
        fill: $orange;
      }

      span {
        color: $green;
      }
    }
  }
}

.blockClass {
  display: block !important;
}

.noneClass {
  display: none !important;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.pointer {
  cursor: pointer;
}

.tablev2 .DatePicker .DatePicker__calendarContainer {
  z-index: 1000 !important;
}

.no-allowed *,
.no-allowed {
  cursor: not-allowed !important;
  color: #ccc !important;
  fill: #ccc !important;
}

.no-allowed:hover *,
.no-allowed:hover {
  color: #ccc !important;
  fill: #ccc !important;
}

.marginLeft5 {
  margin-left: 5px;
}

.not100 {
  th.MuiTableCell-root:nth-child(1) {
    width: 159px !important;
    padding-right: 5px;
  }

  th.MuiTableCell-root:not(:first-child) {
    width: 235px !important;
  }

  &>div>div>div>div>div:last-child {
    box-shadow: none !important;
    min-width: 354px !important;
  }
}

.tangle-left {
  &:before {
    content: " ";
    width: 0;
    height: 0;
    border-top: 4px solid rgba(0, 0, 0, 0);
    border-bottom: 4px solid rgba(0, 0, 0, 0);
    border-right: 5px solid rgba(1, 166, 153, 1);
    position: absolute;
    left: -16px;
    top: -1px;
    border-left: 5px solid rgba(0, 0, 0, 0);
  }

  &:after {
    content: " ";
    width: 0;
    height: 0;
    border-top: 4px solid rgba(0, 0, 0, 0);
    border-bottom: 4px solid rgba(0, 0, 0, 0);
    border-left: 5px solid rgba(1, 166, 153, 1);
    position: absolute;
    right: -11px;
    top: -1px;
    border-right: 5px solid rgba(0, 0, 0, 0);
  }


}

.norna-popover {
  top: 65px !important;
  z-index: 99999 !important;

  .MuiPaper-root {
    top: 10px !important;
    left: 0 !important;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  }
}

button.no-focus:focus {
  color: $white  !important;
  background-color: $orange  !important;
  opacity: 1;
}

.account-menus>div:hover>svg {
  color: $green;
  fill: $green;
}

.triangle {
  width: 0;
  height: 0;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid #fff;
  z-index: 10000;
  position: absolute;
  top: -8px;
  left: calc(50% - 5px);
}

.MuiTableCell-root {
  border-bottom: none !important;
}

///dashboard table tr>td hover style
table.MuiTable-root.table-custom>tbody.MuiTableBody-root>tr.MuiTableRow-root,
table.MuiTable-root.table-custom td.MuiTableCell-body table.MuiTable-root>tbody.MuiTableBody-root>tr.MuiTableRow-root {
  &>td {
    // &:nth-child(3)>div {
    //   border-left: 5px solid transparent;
    // }

    &:nth-last-child(2)>* {
      border-right: 5px solid transparent;
    }
  }

  &.hover>td,
  &:hover>td {
    background: #f5f6f7 !important;

    &:nth-child(3)>div {
      border-left: 0px solid $green;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &:nth-last-child(2)>* {
      border-right: 5px solid $green;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

  }
}

table.MuiTable-root.table-custom-v2>tbody.MuiTableBody-root>tr.MuiTableRow-root,
table.MuiTable-root.table-custom-v2 td.MuiTableCell-body table.MuiTable-root>tbody.MuiTableBody-root>tr.MuiTableRow-root {
  &>td {
    &:nth-child(2)>div {
      border-left: 5px solid transparent;
    }

    &:nth-last-child(2)>* {
      border-right: 5px solid transparent;
    }
  }

  &.hover>td,
  &:hover>td {
    background: rgba(0, 0, 0, .02) !important;

    & > div > [class*="shadowBgCell"] {
      background: none;
    }

    & > .new-table-tablecell {
      background: rgba(0, 0, 0, .02) !important;
    }

    & > button > div > div > div[class*="tableCellTwo"] {
      background: rgba(0, 0, 0, .02) !important;
    }

    &:nth-child(2)>div {
      border-left: 5px solid $green;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &:nth-child(3)>div {
      border-left: 0px solid transparent;
      border-top-left-radius: 10px;
    }

    &:nth-last-child(2)>* {
      border-right: 5px solid $green;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}

// Popular attributes 页面表格样式
.popular-attributes table.MuiTable-root.table-custom>tbody.MuiTableBody-root>tr.MuiTableRow-root,
.popular-attributes td.MuiTableCell-body table.MuiTable-root>tbody.MuiTableBody-root>tr.MuiTableRow-root {
  &>td {
    &:nth-child(2)>div {
      border-left: 5px solid transparent;
    }

    &:nth-child(3)>div {
      border-left: none;
    }

    &:nth-last-child(2)>* {
      border-right: 1px solid #d8d8d8;
    }
  }

  &.hover>td,
  &:hover>td {
    background: #f5f6f7 !important;

    &:nth-child(2)>div {
      border-left: 5px solid $green;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &:nth-child(3)>div {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }

    &:nth-last-child(2)>* {
      border-right: 5px solid $green;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

  }
}

.btn-hover {
  display: flex;
  @extend .flex-center;

  &:hover {
    background: #F1F1F1;
    border-radius: 10%;
  }
}

/**
* td 高亮category 这个单元格 ，当鼠标放到此行时
*/

tr.MuiTableRow-root:hover>td.MuiTableCell-root>div.tablecell-category {
  color: $green  !important
}


.customer-vendor img {
  height: 24px;
  width: auto;
}

.dialog-log-container>img {
  display: block;
  height: 100%;
  width: auto;
  margin: 0 auto;
}

// 2022/04/22 导致 export jpg 一片空白，目前先注释，再找方案处理 frame 导致无法点击问题
body>iframe {
  z-index: -1 !important;
}

// 2022/08/18 Discount depth and width 页面下面的水平滚动条隐藏起来
.not100>div>div>div>div {
  overflow: auto;
}

.tooltip-hide {
  opacity: 0;
  pointer-events: none;
}

.MuiTooltip-popper {
  z-index: 999999 !important;
}

// Filter
.norna-tree .cell-mouse-effect-no-display-no-within:focus-within {
  background-color: transparent !important;
}

.norna-tree .cell-mouse-effect-no-display-no-within:hover {
  background-color: #e6eaea !important;
  ;
}

// norna-progress-bar
.norna-progress-bar {
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  height: 3px;
  background-color: #ec7765;
}

.norna-progress-bar-animation {
  animation: norna-progress-bar-loading 60s ease-out;
  animation-fill-mode: forwards;
}

.norna-progress-bar-animation-2 {
  animation: norna-progress-bar-loading 200s ease-out;
  animation-fill-mode: forwards;
}

@keyframes norna-progress-bar-loading {
  0% {
    width: 1%;
  }

  100% {
    width: 98%;
  }
}

.new-pricing-dashboard .tablecell-category {
  width: 184px !important;
}

// 禁用状态
.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.dynamic-dashboard_setting-select .disabled {
  opacity: 0.7 !important;
  pointer-events: none;
}

// antd - tooltip
.ant-tooltip-arrow::after,
.ant-tooltip-arrow::before {
  background-color: #fff !important;
}

.ant-tooltip-inner {
  padding: 20px !important;
  box-shadow: 0 2px 9px 0 rgba(0,0,0,.3)!important;
  font-size: 12px !important;
  line-height: 19px;
  max-width: 322px !important;
  line-height: 1.4em !important;
  background-color: #fff !important;
  color: #666 !important;
  max-height: 300px !important;
  overflow: auto !important;
  font-family: "Nunito Sans", sans-serif;
}

// ant-notification
.ant-notification {
  z-index: 9999993 !important;
}

.ant-float-btn-square {
  width: 56px !important;
  display: flex;
  justify-content: center;

  .ant-float-btn-body {
    width: 48px !important;
  }
}

.select-changed {
  .base-select_selector-selected {
    border: 1px solid #EC7765 !important;
  }
  .base-select_selector-selection {
    color: #EC7765 !important;
  }
  .base-select_selector-arrow {
    fill: #EC7765 !important;
  }
}

.switch-changed {
  .switch-handle {
    background-color: #EC7765 !important;
  }

  span[class*=activeItem] {
    color: #EC7765 !important;;
  }
}

.druikit-switch-changed {
  .switch_label-item-left-active,
  .switch_label-item-right-active {
    color: #EC7765 !important;
  }

  .switch_switch-handle {
    background-color: #EC7765 !important;
  }
}

.tree-dropdown-changed,
.dropdown-changed {
  border: 1px solid #EC7765 !important;
  
  div[class*=selectorSelection] {
    color: #EC7765 !important;
  }
  div[class*=selectorArrow] > svg {
    fill: #EC7765 !important;
  }
}

.druikit-multi-select-no-ok-button {
  .base-select_dropdown > div:last-child {
    display: none !important;
  }
}

.responder-wrapper ul,
.responder-wrapper ol {
  list-style-position: inside;
}

.executeButton {
  transition: opacity 0.5s;

  &:hover {
      opacity: 0.7;
      transition: opacity 0.5s;
  }
}

.executeLoading {
  cursor: auto !important;
}

.loadCompletedButton {
  cursor: auto !important;
  pointer-events: none !important;
}

// Graph button 中线图 slider 样式
.ant-slider-track {
  background-color: rgb(1, 166, 153) !important;
}

.ant-slider-handle::after {
  background-color: rgb(1, 166, 153) !important;
  box-shadow: none !important;
}

// ant-badge
.ant-badge-status {
  width: 10px !important;
  height: 10px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ant-badge-status-dot {
  width: 10px !important;
  height: 10px !important;
}